@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Noto+Sans+Mono:wght@100..900&display=swap');

body {
    height: 100vh;
    margin: 0;
    overflow: hidden;
}

#vertical-line {
    background: linear-gradient(#000, #000) no-repeat center/2px 100%;
}

/*.font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}*/
.font-mono {
    font-family: "Noto Sans Mono";
}

/*.font-mono {
    font-family: ui-monospace;
}*/

button {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #333;
    text-decoration: underline;
    cursor: pointer;
}

hr {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: black;
}

/* animation */

.animate-pulse {
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

}

@keyframes pulse {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }
}

.animate-background-grey-red {
    animation: background-fade-grey-red 2s infinite;
}

.animate-background-grey-red-short {
    animation: background-fade-grey-red 0.5s;
}

@keyframes background-fade-grey-red {
    0% {
        background-color: rgba(34, 34, 34, 0.5)
    }

    50% {
        background-color: rgba(255, 0, 0, 0.5)
    }

    100% {
        background-color: rgba(34, 34, 34, 0.5)
    }

}

.animate-color-red {
    animation: color-red 1.5s;
}

@keyframes color-red {
    0% {}

    50% {
        color: rgba(255, 0, 0, 1);
        font-weight: bold;
    }

    100% {}

}


.animate-pulse-color {
    animation: pulse-color 3s;
}

@keyframes pulse-color {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}