@import "https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Noto+Sans+Mono:wght@100..900&display=swap";
body {
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

#vertical-line {
  background: linear-gradient(#000, #000) center / 2px 100% no-repeat;
}

.font-mono {
  font-family: Noto Sans Mono;
}

button {
  color: #333;
  cursor: pointer;
  border: none;
  font-family: arial, sans-serif;
  text-decoration: underline;
  background: none !important;
  padding: 0 !important;
}

hr {
  color: #000;
  flex-direction: row;
  width: 100%;
  display: flex;
}

.animate-pulse {
  animation: 1.5s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.animate-background-grey-red {
  animation: 2s infinite background-fade-grey-red;
}

.animate-background-grey-red-short {
  animation: .5s background-fade-grey-red;
}

@keyframes background-fade-grey-red {
  0% {
    background-color: #22222280;
  }

  50% {
    background-color: #ff000080;
  }

  100% {
    background-color: #22222280;
  }
}

.animate-color-red {
  animation: 1.5s color-red;
}

@keyframes color-red {
  0% {
  }

  50% {
    color: red;
    font-weight: bold;
  }

  100% {
  }
}

.animate-pulse-color {
  animation: 3s pulse-color;
}

@keyframes pulse-color {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/*# sourceMappingURL=index.0a349a78.css.map */
